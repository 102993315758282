.sign-in-page-wrapper {
  margin: 0;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background-size: cover;
  background-position: center;
  top: 0;
  left: 0;

  > .box {
    display: table;
    padding: 10px 50px 30px 50px;
    align-self: center;
    position: relative;
    margin: auto;
    top: 150px;
    border-radius: 5px;
    background-color: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(9px); // This be the bl
    z-index: 100;

    @media only screen and (max-width: 750px) {
      width: calc(100% - 40px);
      padding: 10px;
      top: 300px;
    }

    > .header {
      width: 100%;
      padding: 10px 0;
      font-size: 25px;
      font-family: "Open Sans";
      text-align: center;
      color: rgb(62, 62, 62);

      @media only screen and (max-width: 750px) {
        font-size: 30px;
      }
    }

    > .form {
      max-width: 300px;
      margin: auto;

      @media only screen and (max-width: 750px) {
        max-width: none;
        margin: auto 20px;
      }

      > .form-field {
        width: calc(100% - 12px);
        border: 0;
        outline: 0;
        font-family: "Open Sans";
        padding: 3px 6px;
        margin-bottom: 10px;
        border-radius: 2px;
        font-size: 16px;
        font-weight: 300;

        @media only screen and (max-width: 750px) {
          font-size: 20px;
        }

        &::placeholder {
          color: #ccc;
          font-weight: 300;
        }
      }

      > .form-field-label {
        font-size: 12px;
        color: rgb(117, 117, 117);
        display: block;
        padding-bottom: 5px;

        @media only screen and (max-width: 750px) {
          font-size: 16px;
        }
      }

      > .submit-button {
        border: none;
        outline: none;
        background-color: rgb(85, 200, 85);
        color: white;
        padding: 3px 30px;
        border-radius: 20px;
        margin: auto;
        display: block;
        font-family: "Open Sans";
        font-size: 16px;
        cursor: pointer;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        transition: all 0.2s;
        margin-top: 20px;

        @media only screen and (max-width: 750px) {
          font-size: 20px;
        }

        &:hover {
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        }
      }

      > .account-mode-swap-row {
        padding-top: 10px;
        color: rgb(55, 55, 55);
        font-size: 12px;
        text-align: center;

        @media only screen and (max-width: 750px) {
          font-size: 16px;
        }

        > a {
          text-decoration: underline;
          cursor: pointer;
        }
      }
    }
  }
}
