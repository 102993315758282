            @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600&display=swap");

            #wrapper {
                padding: 20px;

                @media only screen and (max-width: 900px) {
                    padding: 10px;
                }
            }

            #header-row {
                justify-content: space-between;
                display: flex;
                width: 100%;
                padding-bottom: 20px;

                @media only screen and (max-width: 900px) {
                    padding-bottom: 10px;
                }

                >div {
                    >#logo {
                        height: 40px;
                        border-radius: 3px;

                        @media only screen and (max-width: 550px) {
                            height: 30px;
                        }
                    }

                    >#sign-out-button {
                        margin-right: 10px;
                        border: none;
                        background-color: transparent;
                        cursor: pointer;
                        font-family: "Open Sans";
                        color: rgb(33, 33, 33);
                        font-size: 15px;

                        @media only screen and (max-width: 900px) {
                            font-size: 18px;
                        }

                        @media only screen and (max-width: 550px) {
                            font-size: 16px;
                        }
                    }

                    >#add-location-button {
                        font-size: 15px;
                        border-radius: 20px;
                        height: 30px;
                        margin-top: 5px;
                        border: none;
                        outline: none;
                        background-color: #34d354;
                        color: white;
                        padding: 5px 20px;
                        font-family: "Open Sans", sans-serif;
                        font-weight: 500;
                        line-height: 1em;
                        text-align: center;
                        transition: all 0.2s;
                        box-shadow: 0 0 2px rgba(0, 0, 0, 0.3);
                        cursor: pointer;

                        @media only screen and (max-width: 900px) {
                            font-size: 18px;
                        }

                        @media only screen and (max-width: 550px) {
                            font-size: 15px;
                            padding: 3px 15px;
                            margin-top: 2px;
                        }

                        &:hover {
                            box-shadow: 0 0 5px rgba(0, 0, 0, 0.4);
                        }
                    }
                }
            }

            .gauze {
                width: 100%;
                height: 100%;
                position: fixed;
                top: 0;
                left: 0;
                background-color: rgba(0, 0, 0, 0.5);
                backdrop-filter: blur(1px); // This be the bl
                z-index: 100;
            }

            #page-contents {
                width: 100%;
                clear: both;
                grid-template-columns: 65% 35%;
                display: grid;
                grid-gap: 10px;

                @media only screen and (max-width: 900px) {
                    display: block;
                }
            }

            #map-wrapper {
                height: 600px;
                float: left;
                border-radius: 3px;
                height: calc(92vh);
                background-color: rgb(239, 239, 239);

                @media only screen and (max-width: 900px) {
                    width: calc(100%);
                    height: 300px;
                }
            }

            #location-wrapper {
                margin-left: 8px;
                border-radius: 4px;
                padding: 15px;
                height: calc(92vh - 30px);
                overflow-y: scroll;
                overflow-x: hidden;
                float: left;
                border: 1px solid #707070;


                &::-webkit-scrollbar {
                    width: 2px;
                    border-radius: 4px;
                }

                /* Track */
                &::-webkit-scrollbar-track {
                    background: #f1f1f1;
                    border-radius: 4px;
                }

                /* Handle */
                &::-webkit-scrollbar-thumb {
                    background: rgb(211, 211, 211);
                    transition: all 0.2s;
                    border-radius: 4px;

                    &:hover {
                        background: rgb(96, 96, 96);
                    }
                }

                @media only screen and (max-width: 900px) {
                    margin: 0;
                    width: calc(100% - 30px);
                    height: 450px;
                    margin-top: 10px;
                }
            }

            #location-filter {
                border: 1px solid #eee;
                border-radius: 15px;
                outline: none;
                font-family: "Open Sans", sans-serif;
                padding: 3px 9px;
                width: calc(100% - 18px);
                font-weight: 300;
                margin-bottom: 20px;
                font-size: 16px;

                @media only screen and (max-width: 900px) {
                    font-size: 20px;
                    border-radius: 20px;
                }

                &::placeholder {
                    color: #ccc;
                }
            }