.location-card {
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: white;
  width: calc(100%);
  margin: auto;
  border-radius: 5px;
  margin-bottom: 10px;
  font-family: "Open Sans";
  border: 1px solid #ccc;

  &.active {
    box-shadow: none;
  }

  > div {
    > .location-card-header {
      width: calc(100% - 20px);
      height: 25px;
      font-weight: 500;
      font-size: 18px;
      padding: 10px 10px 0 10px;
      color: #757575;
      cursor: pointer;

      @media only screen and (max-width: 900px) {
        font-size: 22px;
      }
    }

    > .location-card-body {
      font-size: 13px;
      display: flex;
      justify-content: space-between;
      padding: 10px;
      padding-top: 5px;
      color: #b0b0b0;
      cursor: pointer;

      @media only screen and (max-width: 900px) {
        font-size: 16px;
      }

      @media only screen and (max-width: 550px) {
        display: block;
      }

      > div {
        > .location-card-icons {
          width: 9px;
          margin-right: 5px;
          position: relative;
          top: 3px;
        }
      }

      > .location-phone-number {
        min-width: 90px;
        align-content: flex-end;

        > a {
          color: #b0b0b0;
        }
      }
    }

    > .location-card-actions {
      display: flow-root;
      background-color: #fafafa;
      border-radius: 0 0 3px 3px;
      padding: 10px;

      > .location-card-action {
        outline: none;
        border: none;
        font-family: "Open Sans";
        float: right;
        border-radius: 20px;
        color: white;
        font-size: 13px;
        padding: 2px 12px;
        cursor: pointer;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
        transition: 0.2s;

        @media only screen and (max-width: 900px) {
          font-size: 17px;
        }

        &:hover {
          box-shadow: 0 0 4px rgba(0, 0, 0, 0.4);
        }

        &.archive {
          background-color: #ad2b2b;
          margin-left: 5px;
        }

        &.modify {
          background-color: #2b4ead;
        }

        > .location-card-action-icon {
          width: 8px;
          margin-right: 3px;

          @media only screen and (max-width: 900px) {
            width: 12px;
          }
        }
      }
    }
  }
}
