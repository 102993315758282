                    .modal {
                        width: 450px;
                        background-color: white;
                        box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
                        border-radius: 10px;
                        position: fixed;
                        top: 150px;
                        margin-left: calc(50% - 225px);
                        transition: all 0.2s;
                        z-index: 10000;
                        display: block;
                        @media only screen and (max-width: 900px) {
                            width: calc(100% - 20px);
                            margin: auto;
                            top: 300px;
                        }
                        &.hidden {
                            display: none;
                        }
                        &.visisble {
                            display: block;
                        }
                        >.modal-header {
                            display: flow-root;
                            padding: 15px 30px 0 30px;
                            font-size: 20px;
                            color: #d0d0d0;
                            font-weight: 400;
                            @media only screen and (max-width: 900px) {
                                font-size: 25px;
                            }
                        }
                        >.modal-contents {
                            padding: 30px;
                            >.infowrapper {
                                display: flex;
                                >.modal-input-label {
                                    // width: calc(100% - 10px);
                                    // display: block;
                                    padding: 5px;
                                    padding-left: 0px;
                                    font-size: 12px;
                                    color: #d0d0d0;
                                    font-family: "Open Sans";
                                    @media only screen and (max-width: 900px) {
                                        font-size: 12px;
                                    }
                                }
                                >.icon {
                                    width: calc(5% - 4px);
                                    // padding: 5px;
                                    padding-left: 2px;
                                }
                                >.modal-input-info {
                                    width: 200px;
                                    display: block;
                                    padding: 5px;
                                    padding-left: 7px;
                                    font-size: 10px;
                                    color: #d0d0d0;
                                }
                            }
                            >.modal-input {
                                width: calc(100% - 24px);
                                padding: 3px 12px;
                                border-radius: 20px;
                                border: 1px solid #ccc;
                                font-size: 15px;
                                font-weight: 300;
                                font-family: "Open Sans";
                                @media only screen and (max-width: 900px) {
                                    font-size: 21px;
                                    margin-bottom: 15px;
                                }
                                &::placeholder {
                                    color: #ccc;
                                }
                                &.wrapper {
                                    padding: 0;
                                    width: calc(100%);
                                    >.address-input__control {
                                        border: none;
                                        border-radius: 40px;
                                        min-height: 0;
                                        >.address-input__value-container {
                                            padding: 0 12px;
                                            >div {
                                                margin: 0;
                                                >.address-input__input>input {
                                                    font-size: 15px;
                                                    font-weight: 300;
                                                    font-family: "Open Sans";
                                                }
                                            }
                                            >.address-input__placeholder {
                                                color: #ccc;
                                            }
                                        }
                                        >.address-input__indicators {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                        >.modal-actions {
                            display: flex;
                            padding: 20px 30px 30px 30px;
                            justify-content: space-between;
                            >div {
                                >.modal-action-button {
                                    border: none;
                                    border-radius: 20px;
                                    padding: 4px 11px;
                                    color: white;
                                    text-transform: uppercase;
                                    font-family: "Open Sans";
                                    font-size: 11px;
                                    cursor: pointer;
                                    box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);
                                    transition: all 0.2s;
                                    @media only screen and (max-width: 900px) {
                                        font-size: 17px;
                                        padding: 5px 15px;
                                    }
                                    &:hover {
                                        box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
                                    }
                                    &.right {
                                        float: right;
                                        margin-left: 10px;
                                    }
                                    &.neutral {
                                        background-color: rgb(187, 187, 187);
                                    }
                                    &.modify {
                                        background-color: #2b4ead;
                                    }
                                    &.create {
                                        background-color: #2bad2f;
                                    }
                                    &.archive {
                                        background-color: #ad2b2b;
                                    }
                                }
                            }
                        }
                    }